@import "constant";

header {
  position: relative;
  top: 0;
  left: 0;
  //right: 0;
  height: $headerHeight;
  width: 100%;
  z-index: 2;

  .logo-container {
    line-height: $headerHeight;
    height: $headerHeight;
    display: inline-block;
    float: left;
    margin-left: 40px;
  }

}

.header-expander {
  display: none;
  z-index: 100000003;
  line-height: $headerHeight;
  color: white;
  transition: color 0.5s ease;
}

nav {
  line-height: $headerHeight;
  font-size: 14px;
  position: absolute;
  transition: width 0.5s ease;
  right: 0;
  top: 0;
  z-index: 1000001;

  ul {
    display: flex;
    flex-direction: row;
    //padding-right: 20px;
    /*justify-content: space-around;*/
    li {
      display: inline-block;
      margin: 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  a {
    color: white;
  }
}

/* theme */
header {
  background-color: #333333CC;
  transition: height 0.5s ease 0s;
  overflow: hidden;

  a {
    color: #fff;

    &:hover {
      color: #b2b2b2
    }
  }

  &.white {
    background-color: #ffffff;

    a {
      color: #000;
    }
  }

}
