@import "constant";

html,
body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.adm-list-item-content-main {
  font-size: 14px;
}

.normal-distance > span,
a,
.adm-list-item-content-main > span,
a {
  margin-right: 5px;
}

img {
  max-width: 100vw;
}

.all-sent {
  float: right;
}

.operations {
  float: right;
}

.highlight {
  font-size: 24px;
  color: red;
}

.clickable {
  color: #1677FF;
  transition: opacity ease-in-out 0.2s;
  margin-right: 5px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
}
